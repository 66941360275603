<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-tabs
      v-model="currentTabIndex"
      active-nav-item-class="font-weight-bold text-uppercase text-success"
      class="mt-3"
    >
      <b-tab>
        <template #title>
          <i :class="iconState('ri-file-edit', currentTabIndex === 0)" /> Pendings
        </template>
        <ClosingPending
          ref="scPending"
          :tab-index="currentTabIndex"
          @show="showClosing"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <i :class="iconState('ri-file-history', currentTabIndex === 1)" /> Closed
        </template>
        <ClosingHistory
          ref="scHistory"
          :tab-index="currentTabIndex"
          @show="showClosing"
        />
      </b-tab>
    </b-tabs>

    <b-modal
      id="modalClosing"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      content-class="full-height"
      modal-class="full-height"
      aria-hidden="false"
    >
      <b-tabs
        active-nav-item-class="font-weight-bold text-uppercase text-success"
        content-class="scrollable"
      >
        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-stack-overflow-line h4" />
              <b class="is-tab-active">Products</b>
            </div>
          </template>
          <b-row class="mx-0 mt-1 mb-0">

            <b-col
              cols="12"
              class="px-1 px-md-2"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="2"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="PO Number"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.references.purchase_number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Transfer Number"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.references.transfer_number"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Deliver To"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.headers.destination_name"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Delivery From"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.headers.origin_name"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Group"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.headers.group_name"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Remarks"
                    class="mb-2"
                  >
                    <strong
                      v-text="stockRequest.remarks || 'no remarks'"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              class="px-0 px-md-1"
            >
              <b-table
                ref="tableProducts"
                small
                hover
                striped
                bordered
                responsive
                show-empty
                class="mb-0"
                :items="stockRequest.details"
                :busy="tableProducts.busy"
                :filter="tableProducts.filter"
                :fields="tableProducts.fields"
                :stacked="tableProducts.stacked"
                :filter-included-fields="tableProducts.filterOn"
              >
                <template #cell(index)="{ index }">
                  <div
                    class="text-md-nowrap"
                    v-text="tableRowNumber(tableProducts, index)"
                  />
                </template>

                <template #cell()="{ value }">
                  <div
                    class="text-md-nowrap d-flex align-items-center min-h-35px px-1"
                    v-text="value"
                  />
                </template>

                <template #cell(product_name)="{ value }">
                  <div class="text-md-nowrap d-flex align-items-center min-h-35px px-1">
                    <strong
                      class="font-weight-bolder"
                      v-text="value"
                    />
                  </div>
                </template>

                <template #cell(posting_quantity)="{ value }">
                  <div class="text-md-nowrap d-flex justify-content-center align-items-center min-h-35px">
                    <strong
                      class="font-weight-bolder"
                      v-text="value"
                    />
                  </div>
                </template>

                <template #table-busy>
                  <div class="text-center py-5">
                    <b-icon
                      icon="stopwatch"
                      font-scale="5"
                      animation="cylon"
                    />
                    <p class="h3 py-2">
                      <strong>Loading . . .</strong>
                    </p>
                  </div>
                </template>

              </b-table>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-todo-line h4" />
              <b class="is-tab-active">Delivery Details</b>
            </div>
          </template>
          <b-row class="mx-0 mt-1 mb-0">
            <b-col
              cols="12"
              class="px-1 px-md-2"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label-for="fCustomerDirect"
                    class="mb-1"
                  >
                    <b-form-checkbox
                      id="fCustomerDirect"
                      v-model="stockRequest.delivery_details.customer_direct"
                      switch
                      value="1"
                      name="customer_direct"
                      unchecked-value="0"
                      disabled
                    >
                      <strong>Direct Deliver to Customer?</strong>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Mobile Number"
                    label-for="fvCustomerMobile"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvCustomerMobile"
                      v-model="stockRequest.delivery_details.mobile_number"
                      type="text"
                      placeholder="no customer mobile"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="9"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Name"
                    label-for="fvCustomerName"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvCustomerName"
                      v-model="stockRequest.delivery_details.customer"
                      type="text"
                      placeholder="no customer name"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Address"
                    label-for="fvCustomerAddress"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-textarea
                      id="fvCustomerAddress"
                      v-model="stockRequest.delivery_details.address"
                      rows="4"
                      max-rows="6"
                      maxlength="50"
                      placeholder="no customer address"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-calendar-line h4" />
              <b class="is-tab-active">Delivery Schedule</b>
            </div>
          </template>

          <b-row class="mx-0 mt-1 mb-0">
            <b-col
              cols="12"
              class="px-1 px-md-2"
            >
              <b-row>

                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Trip Type"
                    label-for="fvScheduleTripType"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleTripType"
                      v-model="stockRequest.schedule_details.trip_type"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="3"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Date"
                    label-for="fvScheduleDate"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleDate"
                      v-model="stockRequest.schedule_details.date"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-row>

                    <b-col
                      cols="12"
                      md="6"
                      class="px-1 px-md-2"
                    >
                      <b-form-group
                        label="Driver"
                        label-for="fvScheduleDriver"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="fvScheduleDriver"
                          v-model="stockRequest.schedule_details.driver_name"
                          type="text"
                          maxlength="50"
                          placeholder="no driver"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="6"
                      class="px-1 px-md-2"
                    >
                      <b-form-group
                        label="Mobile Number"
                        label-for="fvScheduleMobile"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="fvScheduleMobile"
                          v-model="stockRequest.schedule_details.mobile_number"
                          type="text"
                          maxlength="50"
                          placeholder="no mobile number"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                  class="px-1 px-md-2"
                >
                  <b-form-group
                    label="Helper"
                    label-for="fvScheduleHelper"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="fvScheduleHelper"
                      v-model="stockRequest.schedule_details.helper"
                      type="text"
                      maxlength="50"
                      placeholder="no helper"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-row>

                    <b-col
                      cols="12"
                      md="6"
                      class="px-1 px-md-2"
                    >
                      <b-form-group
                        label="Vehicle Plate"
                        label-for="fvScheduleVehiclePlate"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="fvScheduleVehiclePlate"
                          v-model="stockRequest.schedule_details.vehicle_plate"
                          type="text"
                          maxlength="25"
                          placeholder="no vehicle plate"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="6"
                      class="px-1 px-md-2"
                    >
                      <b-form-group
                        label="Vehicle Name"
                        label-for="fvScheduleVehicleName"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-input
                          id="fvScheduleVehicleName"
                          v-model="stockRequest.schedule_details.vehicle_name"
                          type="text"
                          maxlength="50"
                          placeholder="no helper"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                  </b-row>
                </b-col>

              </b-row>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab class="p-2">
          <template #title>
            <div class="text-md-nowrap">
              <i class="ri-history-line h4" />
              <b class="is-tab-active ml-1">Tracking</b>
            </div>
          </template>

          <Timeline
            :stock-request="stockRequest"
          >
            <template #approvals="request">
              <Signatory
                :stock-request="request"
                :approver="requesterSignatory"
              />
              <Signatory
                v-for="(approver, key) in request.approvals"
                :key="key"
                :stock-request="request"
                :approver="approver"
              />
            </template>
          </Timeline>
        </b-tab>
      </b-tabs>
      <template #modal-footer="{ cancel }">
        <div class="flex-grow-1 d-flex justify-content-between mb-0 pb-0">
          <div />
          <div>
            <b-button
              v-if="state.closing"
              variant="primary"
              class="mr-2"
              :disabled="state.busy"
              @click.prevent="$event => validateClosing($event)"
            >
              Mark as Closed
            </b-button>
            <b-button
              variant="outline-dark"
              :disabled="state.busy"
              @click.prevent="cancel()"
            >
              Close <span class="d-none d-md-inline-block">Window</span>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
// import FUSE from 'fuse.js'
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import { core } from '@/config/pluginInit'

import ClosingPending from '@/views/lo/closing/Pending'
import ClosingHistory from '@/views/lo/closing/History'
import Timeline from '@/components/custom/timeline/Base'
import Signatory from '@/components/custom/timeline/Signatory'

export default {

  name: 'LogisticsOfficerClosingBase',

  middleware: ['auth', 'lo'],

  metaInfo: () => ({
    title: 'Closing'
  }),

  components: {
    ClosingPending,
    ClosingHistory,
    Timeline,
    Signatory
  },

  mixins: [
    MISC,
    FORMATTER
  ],

  data () {
    return {
      currentTabIndex: this.getCurrentTabIndexByURL(),
      state: {
        busy: false,
        closing: false
      },
      stockRequest: {
        id: null,
        stage: 0,
        references: {
          document_number: null,
          transfer_number: null,
          sap_doc_entry: null,
          purchase_number: null
        },
        headers: {
          destination: null,
          destination_name: null,
          destination_code: null,
          origin: null,
          origin_name: null,
          origin_code: null,
          group: null,
          group_name: null,
          group_code: null
        },
        delivery_details: {
          customer_direct: 0,
          customer: null,
          address: null,
          mobile_number: null
        },
        schedule_details: {
          date: null,
          trip_type: null,
          vehicle: null,
          vehicle_plate: null,
          vehicle_name: null,
          driver: null,
          driver_name: null,
          mobile_number: null,
          helper: null
        },
        details: [],
        approvals: [],
        remarks: null
      },
      requesterSignatory: {
        stage: 0,
        approver_label: 'Requester',
        approver_action: 'Requisition',
        approver_name: null,
        last_seen: null,
        status: 'Requested',
        status_at: null,
        remarks: null
      },
      tableProducts: {
        busy: false,
        filter: {
          search: null
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 1, key: 'product_name', class: 'min-w-200px' },
          { mobile: 2, key: 'posting_quantity', label: 'Quantity', class: ['text-center', { 'col-2': !this.isMobile() }] }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    getCurrentTabIndexByURL () {
      switch (this.$route.params.closer) {
        case 'histories' : {
          return 1
        }
        default: {
          return 0
        }
      }
    },

    showClosing (stockRequest, useClosing) {
      this.state.closing = useClosing

      const { id, approvable } = stockRequest
      const { delivery_details, schedule_details } = approvable

      this.stockRequest = Object.assign(this.stockRequest, {
        id: id,
        stage: approvable.stage,
        references: {
          document_number: approvable.document_number,
          transfer_number: approvable.transfer_number,
          sap_doc_entry: approvable.sap_doc_entry,
          purchase_number: approvable.purchase_number
        },
        headers: {
          destination: approvable.destination_id,
          destination_name: approvable.destination_name,
          destination_code: approvable.destination_code,
          origin: approvable.origin_id,
          origin_code: approvable.origin_code,
          origin_name: approvable.origin_name,
          group: approvable.group_id,
          group_code: approvable.group_code,
          group_name: approvable.group_name
        },
        delivery_details: {
          customer_direct: Number(approvable.customer_direct),
          customer: delivery_details.customer,
          mobile_number: delivery_details.mobile_number,
          address: delivery_details.address
        },
        schedule_details: {
          date: this.dateShortFormatter(schedule_details?.date || null),
          trip_type: schedule_details?.trip_type || null,
          vehicle: schedule_details?.vehicle || null,
          vehicle_plate: schedule_details?.vehicle_plate || null,
          vehicle_name: schedule_details?.vehicle_name || null,
          driver: schedule_details?.driver || null,
          driver_name: schedule_details?.driver_name || null,
          mobile_number: schedule_details?.mobile_number || null,
          helper: schedule_details?.helper || null
        },
        details: approvable.transfer_details,
        approvals: approvable.approvals,
        remarks: approvable.remarks,
        created_at: approvable.created_at
      })

      this.requesterSignatory = Object.assign(this.requesterSignatory, {
        approver_name: approvable.destination_name,
        status_at: approvable.created_at
      })
      this.$bvModal.show('modalClosing')
    },

    validateClosing ({ target }) {
      this.swalConfirmWithRemarks({
        html: 'Mark Deliveries as Closed?',
        preConfirm: remarks => {
          return this.$refs.scPending.update({
            id: this.stockRequest.id,
            selected: [
              {
                id: this.stockRequest.id,
                remarks: remarks
              }
            ],
            status: 'Closed'
          })
        },
        confirmButtonText: target.innerText
      })
    }
  }
}
</script>
